.live {  
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 168px 1fr 1fr;
    gap: 10px;
    grid-auto-flow: row;
    grid-template-areas:
      "widget1 widget2 widget3 widget4"
      "graph1 graph1 graph2 graph2"
      "graph3 graph3 graph4 graph4";

    @media screen and (max-width:1280px) {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            "widget1 widget2"
            "widget3 widget4"
            "graph1 graph1"
            "graph2 graph2"
            "graph3 graph3"
            "graph4 graph4"; 
    }

    @media screen and (max-width:640px) {
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            "widget1"
            "widget2"
            "widget3"
            "widget4"
            "graph1"
            "graph2"
            "graph3"
            "graph4"
    }
  }
  
  .widget1 { grid-area: widget1; }
  
  .widget2 { grid-area: widget2; }
  
  .widget3 { grid-area: widget3; }
  
  .widget4 { grid-area: widget4; }
  
  .graph1 { grid-area: graph1; }
  
  .graph2 { grid-area: graph2; }
  
  .graph3 { grid-area: graph3; }
  
  .graph4 { grid-area: graph4; }
  