@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-slate-100 dark:bg-neutral-900 dark:text-white;
    }
}

@layer components {
     .select-container {
        @apply w-full
    } 
    .select-container .select__control {
      @apply border-0 bg-inherit;
    }
  
    .select-container .select__control--is-focused {
      @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
    }
  
    .select-container .select__menu {
      @apply bg-neutral-100 dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
    }
  
    .select-container .select__option {
      @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
    }

  
    .select-container .select__indicator-separator {
      @apply hidden
    }

    .select-container .select__single-value {
      @apply text-black dark:text-neutral-200;
    }

    .select-container .select__multi-value {
        @apply bg-inherit m-0
    }

    .select-container .select__multi-value__label {
        @apply dark:text-neutral-200 p-0 text-base text-black
    }

    .select-container .select__value-container {
        @apply p-0
    }

    .select-container .select__indicator {
        @apply dark:text-neutral-200 text-black
    }
}

@layer components {

   .select-alert-container .select-alert__control {
     @apply bg-inherit;
   }
 
   .select-alert-container .select-alert__control--is-focused {
     @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
   }
 
   .select-alert-container .select-alert__menu {
     @apply bg-neutral-100 dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
   }
 
   .select-alert-container .select-alert__option {
     @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-800;
   }
 
   .select-alert-container .select-alert__indicator-separator {
    @apply hidden
   }

   .select-alert-container .select-alert__single-value {
     @apply text-black dark:text-neutral-200;
   }

   .select-alert-container .select-alert__indicator {
       @apply dark:text-neutral-200 text-black
   }
}

@layer components {
    svg {
        @apply dark:text-neutral-200
    }
}

.MuiTablePagination-displayedRows {
   @apply dark:!text-white
}


@layer components {
    .notifications .container {
        @apply dark:bg-neutral-800 !opacity-95
    }

    .notifications .count {
        @apply !hidden
    }

    .notifications .image {
        @apply flex-1 flex items-center justify-center
    }

    .notifications .content {
        @apply flex
    }

    .notifications .message {
        @apply flex-[3]
    }

    .notifications .header-title {
        @apply dark:!text-white
    }

    .notifications .card {
        @apply dark:bg-neutral-800
    }

    .notifications .text {
        @apply dark:text-white
    }

    .notifications .time {
        @apply dark:!text-zinc-400
    }
}

#root {
    width: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* modal bug fix */
.react-responsive-modal-overlay, .react-responsive-modal-container, .react-responsive-modal-modal {
    animation-fill-mode: forwards!important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}
