.widget {  
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-template-rows: 1fr 1.2fr 1fr;
    grid-auto-columns: 1fr;
    gap: 5px 5px;
    grid-auto-flow: row;
    grid-template-areas:
      "provider tracker"
      "value error"
      "updated refresh";
  }
  
  .provider { grid-area: provider; }
  
  .tracker { grid-area: tracker; justify-items: end; }
  
  .value { grid-area: value; }
  
  .error { grid-area: error; }
  
  .updated { grid-area: updated; }
  
  .refresh { grid-area: refresh; }
  